'use strict';

angular.module('enervexSalesappApp').service('SpecificationsService', function(Specification, SpecificationMultipart, SpecificationComment, Attachment, $stateParams, _) {
	this.fetchSpecifications = fetchSpecifications;
	this.fetchSpecificationVersions = fetchSpecificationVersions;
	this.getSpecification = getSpecification;
	this.newSpecification = newSpecification;
	this.updateSpecification = updateSpecification;
	this.deleteSpecification = deleteSpecification;
	this.getComments = getComments;
	this.createComment = createComment;
	this.removeComment = removeComment;
	this.updateComment = updateComment;
	this.createAttachment = createAttachment;
	this.compile = compile;

	function fetchSpecifications() {
		return Specification.query({
			accountId: $stateParams.accountId,
			jobId: $stateParams.jobId
		});
	}

	function fetchSpecificationVersions() {
		return Specification.versions({
			accountId: $stateParams.accountId,
			jobId: $stateParams.jobId,
			id: $stateParams.specificationId
		});
	}

	function compile(spec) {
		return Specification.compile({
			accountId: $stateParams.accountId,
			jobId: $stateParams.jobId,
			id: $stateParams.specificationId
		}, spec);
	}

	function getSpecification() {
		if ($stateParams.version){
			return Specification.version({
				accountId: $stateParams.accountId,
				jobId: $stateParams.jobId,
				id: $stateParams.specificationId,
				version: $stateParams.version
			});

		} else {
			return Specification.get({
				accountId: $stateParams.accountId,
				jobId: $stateParams.jobId,
				id: $stateParams.specificationId
			});

		}
	}

	function newSpecification(spec) {
		return Specification.save({
			accountId: $stateParams.accountId,
			jobId: $stateParams.jobId
		}, spec);
	}

	// function updateSpecificationDetails(spec) {
	// 	return Specification.update({
	// 		accountId: $stateParams.accountId,
	// 		jobId: $stateParams.jobId,
	// 		id: $stateParams.specificationId
	// 	}, spec);
	// }

	function updateSpecification(spec, photo) {
		//console.log('final design copy', design)
		if (photo) {
			var fd = new FormData();
			fd.append('photo', photo);
			var cleaned = _.omit(design, "photo", "updatedBy", "createdBy", "__v", "toJSON", 'deleted');
			_.each(_.keys(cleaned),function(key){
				if (key.indexOf('$')==0){
					return;
				}
				var value = spec[key];
				if (_.isObject(value)) {
					var json = angular.toJson(value);
					fd.append(key, json);
				} else {
					fd.append(key, value);
				}
			})
			return SpecificationMultipart.update({
				accountId: $stateParams.accountId,
				jobId: $stateParams.jobId,
				id: $stateParams.specificationId
			}, fd);
		} else {
			return Specification.update({
				accountId: $stateParams.accountId,
				jobId: $stateParams.jobId,
				id: $stateParams.specificationId
			}, spec);
		}
	}

	function deleteSpecification(spec) {
		return Specification.remove({
			accountId: $stateParams.accountId,
			jobId: $stateParams.jobId,
			id: spec._id
		});
	}

	function getComments() {
		return SpecificationComment.query({
			accountId: $stateParams.accountId,
			jobId: $stateParams.jobId,
			specificationId: $stateParams.specificationId
		});
	}

	function createComment(newComment) {
		return SpecificationComment.save({
			accountId: $stateParams.accountId,
			jobId: $stateParams.jobId,
			specificationId: $stateParams.specificationId
		}, newComment);
	}

	function removeComment(comment) {
		return SpecificationComment.remove({
			accountId: $stateParams.accountId,
			jobId: $stateParams.jobId,
			specificationId: $stateParams.specificationId,
			id: comment._id
		});
	}

	function updateComment(comment) {
		return SpecificationComment.update({
			accountId: $stateParams.accountId,
			jobId: $stateParams.jobId,
			specificationId: $stateParams.specificationId,
			id: comment._id
		}, comment);
	}

	function createAttachment(fd) {
		return Attachment.save({
			accountId: $stateParams.accountId,
			jobId: $stateParams.jobId
		}, fd);
	}
});
